import consumer from "./consumer";

document.addEventListener('turbolinks:load', (event) => {
  consumer.subscriptions.create({ channel: "MessagesNotificationsChannel" }, {
    connected() {
      console.debug("MESSAGES NOTIFICATIONS CHANNEL CONNECTED")
    },
    received(data) {
      const chatListItemId = data.chat_list_item_id;
      const roomId = data.room_id;
      if (data.rooms_with_new_messages_count !== undefined) {
        updateTotalUnreadCount(data.rooms_with_new_messages_count);
      }

      if (data.unread_messages_by_room !== undefined) {
        const count = data.unread_messages_by_room[roomId];
        displayNewMessageDots(roomId);   
      }

      if (data.last_message_username && data.last_message_content && roomId) {
        const lastMessagePreviewElements = document.querySelectorAll(`#chat-list-item-${roomId} .last-message-preview`);
        
        lastMessagePreviewElements.forEach(element => {
          if (element) {
            element.textContent = `${data.last_message_username}: ${data.last_message_content}`;
          }
        });
      }

      const lastMessageUsername = data.last_message_username;
      const lastMessageContent = data.last_message_content;
      const hash = window.location.hash;
      const highlightedItemId = hash ? hash.replace('#', '') : null; 

      const chatListItem = document.querySelector(`.messages-page #room-${highlightedItemId}`);

      if(chatListItem) {
        chatListItem.classList.add('selected-chat-list-item');
      }

      if (data.action === 'update_rooms_list') {
        const hash = window.location.hash;
        const highlightedItemId = hash ? hash.replace('#', '') : null; 
        const chatListUl = document.querySelector(".chat-list ul");

        if (chatListUl && highlightedItemId) {
          chatListUl.innerHTML = data.html;
          const chatListItemMessagesCount = document.querySelector(`.no-sidebar.messages-page .chat-list ul #chat-list-item-${highlightedItemId} .new-room-messages-count`);

          if (chatListItemMessagesCount) {
            chatListItemMessagesCount.classList.add("hidden");
          }
        }
      }

    }
  });

  function updateTotalUnreadCount(count) {
    const unreadCountElementsTop = document.querySelectorAll(".messages-notifications-badge__top-nav");
    const unreadCountElementsBottom = document.querySelectorAll(".messages-notifications-badge__bottom-nav");

    unreadCountElementsTop.forEach(element => {
      element.textContent = count;
      if (count > 0) {
        element.classList.remove('hidden');
      } else {
        element.classList.add('hidden');
      }
    });

    unreadCountElementsBottom.forEach(element => {
      element.textContent = count;
      if (count > 0) {
        element.classList.remove('hidden');
      } else {
        element.classList.add('hidden');
      }
    });
    
  }

  function displayNewMessageDots(roomId) {
    const unreadCountElement = document.querySelector(`.rooms-list-page #chat-list-item-${roomId} .new-room-messages-count`);

    if (unreadCountElement) {
      if (unreadCountElement) {
        unreadCountElement.classList.remove('hidden');
      } else {
        unreadCountElement.classList.add('hidden');
      }
    }

  }

  function fetchAndUpdateUnreadMessagesCount() {
    const getCurrentUserId = (typeof gon !== 'undefined' && gon.current_user) ? gon.current_user : null;
    const userId = getCurrentUserId;
    const url = `/users/${userId}/unread_rooms_count`;

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        updateTotalUnreadCount(data.count);
      })
      .catch(error => console.error('Error fetching unread messages count:', error));
  }

  function refreshUnreadMessageCounts() {
    fetch('/rooms/new_messages_count')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if (Array.isArray(data.rooms_with_new_messages_count)) {
          updateRoomsNewMessagesCount(data.rooms_with_new_messages_count);
        } else {
          console.error('Expected rooms_with_new_messages_count to be an array but got:', data.rooms_with_new_messages_count);
        }
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }
  
function updateRoomsNewMessagesCount(roomCounts) {
  roomCounts.forEach(room => {
    const roomElement = document.querySelector(`.no-sidebar.rooms-list-page .chat-list ul #chat-list-item-${room.uuid} .new-room-messages-count`);

    if (roomElement) {
      if (room.new_messages_count > 0) {
        roomElement.classList.remove('hidden');
      } else {
        roomElement.classList.add('hidden');
      }
    }
  });
}
  document.addEventListener('turbolinks:load', () => {
    if (gon.current_user) { 
      refreshUnreadMessageCounts();
      fetchAndUpdateUnreadMessagesCount();

      window.addEventListener('pageshow', refreshUnreadMessageCounts);
      window.addEventListener('pageshow', fetchAndUpdateUnreadMessagesCount);
    }
  });
});