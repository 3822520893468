import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["entries", "pagination", "spinner"]

  connect() {
    console.debug("InfiniteScroll controller connected");
    this.loading = false;
    this.scrollHandler = this.scroll.bind(this);

    window.addEventListener('scroll', this.scrollHandler);
    window.addEventListener('touchmove', this.scrollHandler);
    window.addEventListener('touchend', this.scrollHandler);
  }
  
  disconnect() {
    window.removeEventListener('scroll', this.scrollHandler);
    window.removeEventListener('touchmove', this.scrollHandler);
    window.removeEventListener('touchend', this.scrollHandler);
  }
  
  scroll() {
    if (!this.hasPaginationTarget) {
      console.debug('No pagination target found');
      return;
    }

    let next_page = this.paginationTarget.querySelector("a[rel='next']");
    if (!next_page || this.loading) {
      console.debug('Scroll blocked:', { hasNextPage: !!next_page, isLoading: this.loading });
      return;
    }
  
    let windowHeight = window.innerHeight;
    let documentHeight = document.documentElement.scrollHeight;
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    
    console.debug('Scroll metrics:', {
      windowHeight,
      documentHeight,
      scrollTop,
      threshold: documentHeight - 100
    });
  
    if ((windowHeight + scrollTop) >= (documentHeight - 100)) {
      console.debug('Loading next page:', next_page.href);
      this.loadNextPage(next_page.href);
    }
  }

  loadNextPage(url) {
    this.loading = true;
    this.showSpinner();
  
    console.debug('Starting load with loading:', this.loading);  
  
    Rails.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        console.debug('Success handler start, loading was:', this.loading);
        this.handleNewContent(data);
        console.debug('Success handler end, loading is:', this.loading);
      },
      error: (error) => {
        console.error("Failed to load next page", error);
        this.loading = false;
        this.hideSpinner();
        console.debug('Error handler end, loading is:', this.loading);
      },
      complete: () => { 
        console.debug('Complete handler, ensuring loading is reset');
        this.loading = false;
        this.hideSpinner();
      }
    });
  }

  handleNewContent(data) {
    try {
      this.entriesTarget.insertAdjacentHTML('beforeend', data.entries);
      this.updatePagination(data.pagination);
      
      this.initializeNewAds();
    } catch (error) {
      console.error('Error in handleNewContent:', error);
    } finally {
      this.loading = false;  
      this.hideSpinner();
    }
  }

  initializeNewAds() {
    const newAdContainers = this.entriesTarget.querySelectorAll('.ad-container:not([data-loaded])');
    
    newAdContainers.forEach(container => {
      container.setAttribute('data-loaded', 'true');
      
      const adInsElement = container.querySelector('ins.adsbygoogle');
      if (adInsElement && !adInsElement.hasAttribute('data-adsbygoogle-status')) {
        (adsbygoogle = window.adsbygoogle || []).push({});
      }
      
      if (!container.hasAttribute('data-controller')) {
        container.setAttribute('data-controller', 'adsense');
      }
    });
  }

  updatePagination(paginationHtml) {
    this.paginationTarget.innerHTML = paginationHtml;
    if (!this.paginationTarget.querySelector("a[rel='next']")) {
      console.debug("Reached the last page");
    }
  }

  showSpinner() {
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.style.display = 'block';
    }
  }

  hideSpinner() {
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.style.display = 'none';
    }
  }
}