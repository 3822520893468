import 'core-js/stable';
import 'regenerator-runtime/runtime';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import '../toastr_config';
require('channels');
import "../suppress_console";

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import $ from 'jquery';
import { initializePushNotifications, updateNotificationToggleUI, handleLogout } from '../push_notifications';

Turbolinks.start();

document.addEventListener('turbolinks:load', () => {
  if (!window.Rails) {
    Rails.start();
    window.Rails = Rails;
  }
});

window.$ = $;
window.jQuery = $;
window.toastr = toastr

import "../stimulus_setup";
import "../lib/custom";
import '../stylesheets/custom_toastr.scss';
import initializeReactApps from '../react_select_initializer';

initializeReactApps();

document.addEventListener('turbolinks:load', function() {
  const homeTab = document.getElementById('home-tab-bottom-nav');

  if (homeTab) {
    homeTab.addEventListener('click', function(event) {
      event.preventDefault();
      const homeUrl = homeTab.getAttribute('href');
      Turbolinks.visit(homeUrl, { replace: true });
    });
  }
});

// Update this in app/javascript/packs/application.js
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.addEventListener('message', function(event) {
    if (event.data && event.data.type === 'STORE_HISTORY') {
      // Store the current URL in sessionStorage for back button
      const currentUrl = event.data.currentUrl || window.location.href;
      sessionStorage.setItem('previousUrl', currentUrl);
      console.log('Stored previous URL for back button:', currentUrl);
    }
    
    if (event.data && event.data.type === 'TURBOLINKS_VISIT') {
      if (typeof Turbolinks !== 'undefined' && event.data.url) {
        Turbolinks.visit(event.data.url);
      }
    }
    
    if (event.data && event.data.type === 'navigate') {
      if (window.location.href !== event.data.url) {
        if (typeof Turbolinks !== 'undefined') {
          Turbolinks.visit(event.data.url);
        } else {
          window.location.href = event.data.url;
        }
      }
    }
  });
}

// Add this to handle back button clicks for notifications
document.addEventListener('click', function(event) {
  const backButton = event.target.closest('.go-back-button, #go-back-button, .link-show-page-back-button');
  if (backButton) {
    const previousUrl = sessionStorage.getItem('previousUrl');
    if (previousUrl) {
      event.preventDefault();
      sessionStorage.removeItem('previousUrl');
      console.log('Navigating back to:', previousUrl);
      
      if (typeof Turbolinks !== 'undefined') {
        Turbolinks.visit(previousUrl);
      } else {
        window.location.href = previousUrl;
      }
      return false;
    }
    // If no previousUrl, let the default handler in navigation.js handle it
  }
});

initializePushNotifications();

$(document).on("click", "#sign-out-link", async function() {
  await handleLogout();
  window.location.href = '/trending';
});

$(document).on('turbolinks:load', function() {
  const getCurrentUser = (typeof gon !== 'undefined' && gon.current_user) ? gon.current_user : null;
  
  if(getCurrentUser) {
    updateNotificationToggleUI();
  }
});

// Make registerDeviceToken available globally if needed
import { registerDeviceToken } from '../push_notifications';
window.registerDeviceToken = registerDeviceToken;
