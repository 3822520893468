import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, deleteToken, isSupported } from 'firebase/messaging';
import $ from 'jquery';

let messaging = null;
let isButtonClicked = false;
let serviceWorkerRegistration = null;
const deviceId = getDeviceId();

// Initialize Firebase messaging if supported
function initializePushNotifications() {
  if (!isSupported() || !('serviceWorker' in navigator)) {
    console.debug("Firebase Cloud Messaging is not supported in this environment.");
    return;
  }
  
  console.debug("Initializing push notifications...");
  
  // First unregister any existing service workers to ensure clean state
  navigator.serviceWorker.getRegistrations().then(registrations => {
    const swUrl = '/firebase-messaging-sw.js?v=' + Date.now();
    
    // Register the service worker with cache busting
    navigator.serviceWorker.register(swUrl)
      .then(registration => {
        console.debug('Service Worker registered with scope:', registration.scope);
        serviceWorkerRegistration = registration;
        
        // Initialize Firebase
        const app = initializeApp(window.firebaseConfig);
        messaging = getMessaging(app);
        
        // Send config to service worker
        if (registration.active) {
          registration.active.postMessage({
            type: 'INITIALIZE_FCM',
            config: window.firebaseConfig
          });
        }
        
        // Set up foreground message handling
        onMessage(messaging, (payload) => {
          console.debug("Foreground notification received:", payload);
          showForegroundNotification(payload);
        });
        
        // Set up notification toggle handling
        setupNotificationToggle();
        
        // Update UI based on current state
        updateNotificationToggleUI();
      })
      .catch(error => {
        console.error('Service Worker registration failed:', error);
      });
  });
}

function setupNotificationToggle() {
  $(document).off("click", ".push-notifications .switch-container")
    .on("click", ".push-notifications .switch-container", async function() {
      if (isButtonClicked) return;
      isButtonClicked = true;
      
      try {
        const isCurrentlyEnabled = $('.push-notifications .switch').hasClass('on');
        const deviceHasPermission = Notification.permission === 'granted';
        
        if (!isCurrentlyEnabled) {
          if (deviceHasPermission) {
            await createTokenAndStore();
            await enableNotificationsForUser();
          } else {
            const permissionGranted = await requestNotificationPermission();
            if (permissionGranted) {
              await createTokenAndStore();
              await enableNotificationsForUser();
            }
          }
        } else {
          await disableNotificationsForUser();
        }
      } catch (error) {
        console.error("Error in toggle notification:", error);
      } finally {
        isButtonClicked = false;
      }
    });
}

function showForegroundNotification(payload) {
  if (!("Notification" in window)) {
    console.debug("This browser does not support notifications");
    return;
  }
  
  if (Notification.permission === "granted") {
    let title = "New Notification";
    let options = {
      body: "You have a new notification",
      icon: "/icon-192x192.png"
    };
    
    if (payload.notification) {
      title = payload.notification.title || title;
      options.body = payload.notification.body || options.body;
      options.icon = payload.notification.icon || options.icon;
    }
    
    if (payload.data) {
      title = payload.data.title || title;
      options.body = payload.data.body || options.body;
      options.icon = payload.data.image || options.icon;
      options.data = { url: payload.data.url || '/' };
    }
    
    const notification = new Notification(title, options);
    
    notification.onclick = function() {
      window.focus();
      notification.close();
      if (options.data && options.data.url) {
        window.location.href = options.data.url;
      }
    };
  }
}

function getDeviceId() {
  let deviceId = localStorage.getItem('device_id');
  
  if (!deviceId) {
    deviceId = generateUUID();
    localStorage.setItem('device_id', deviceId);
  }
  
  return deviceId;
}

function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

async function updateNotificationToggleUI() {
  try {
    const deviceHasPermission = Notification.permission === 'granted';
    
    if (!deviceHasPermission) {
      $('.push-notifications .switch, .push-notifications .slider').removeClass('on').addClass('off');
      return;
    }
    
    const tokenResponse = await $.get('/check_token_presence', { device_id: deviceId });
    const tokenExistsForDevice = tokenResponse.tokenExists;
    
    const preferenceResponse = await fetch('/push_notifications_enabled');
    const preferenceData = await preferenceResponse.json();
    const userPrefersNotifications = preferenceData.enabled;
    
    if (tokenExistsForDevice && deviceHasPermission && userPrefersNotifications) {
      $('.push-notifications .switch, .push-notifications .slider').removeClass('off').addClass('on');
    } else {
      $('.push-notifications .switch, .push-notifications .slider').removeClass('on').addClass('off');
    }
  } catch (error) {
    console.error('Error updating notification toggle UI:', error);
    // Default to off state if there's an error
    $('.push-notifications .switch, .push-notifications .slider').removeClass('on').addClass('off');
  }
}

async function requestNotificationPermission() {
  try {
    const permission = await Notification.requestPermission();
    return permission === 'granted';
  } catch (error) {
    console.debug('Error requesting notification permission:', error);
    return false;
  }
}

async function disableNotificationsForUser() {
  try {
    await $.ajax({
      url: '/disable_push_notifications', 
      method: 'POST',
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
    });
    
    $('.push-notifications .switch, .push-notifications .slider').removeClass('on').addClass('off');
    
    // Also remove the token
    if (messaging) {
      try {
        const currentToken = await getToken(messaging);
        if (currentToken) {
          await deleteToken(messaging, currentToken);
        }
      } catch (tokenError) {
        console.debug('Error removing token:', tokenError);
      }
    }
    
    return true;
  } catch (error) {
    console.debug('Error disabling notifications:', error);
    return false;
  }
}

async function enableNotificationsForUser() {
  try {
    const response = await $.ajax({
      url: '/enable_push_notifications',
      method: 'POST',
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
    });
    
    console.debug("Notifications enabled for the user.");
    $('.push-notifications .switch, .push-notifications .slider').removeClass('off').addClass('on');
    
    return true;
  } catch (error) {
    console.debug('Error enabling notifications:', error);
    return false;
  }
}

async function createTokenAndStore() {
  if (!messaging) {
    console.debug('Firebase messaging is not initialized.');
    return false;
  }
  
  try {
    const currentToken = await getToken(messaging, { serviceWorkerRegistration });
    
    if (!currentToken) {
      console.debug('No token received from Firebase.');
      return false;
    }
    
    await $.ajax({
      url: '/notification_tokens',
      method: 'POST',
      data: { notification_token: { token: currentToken, device_id: deviceId, platform: 'fcm' } },
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
    });
    
    console.debug('Token saved successfully.');
    return true;
  } catch (error) {
    console.debug('Error saving token:', error);
    return false;
  }
}

async function removePushToken() {
  if (!messaging) {
    console.debug('Firebase messaging is not initialized.');
    return;
  }
  
  try {
    const currentToken = await getToken(messaging);
    if (currentToken) {
      await deleteToken(messaging, currentToken);
      console.debug("Token removed from Firebase.");
      
      // Also remove from server
      await $.ajax({
        url: '/notification_tokens/remove',
        method: 'POST',
        data: { token: currentToken, device_id: deviceId },
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      });
      
      console.debug("Token removed from server.");
    }
  } catch (error) {
    console.debug('Error handling push token during logout:', error);
  }
}

async function handleLogout() {
  if (isSupported() && 'serviceWorker' in navigator) {
    try {
      await removePushToken();
    } catch (error) {
      console.debug('Error handling push token during logout:', error);
    }
  }
  
  $('.push-notifications .switch, .push-notifications .slider').removeClass('on').addClass('off');
  await logoutUser();
}

async function logoutUser() {
  try {
    await $.ajax({
      url: '/signout',
      method: 'DELETE',
      data: { device_id: deviceId },
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
    });
    console.debug("User logged out successfully.");
    return true;
  } catch (error) {
    console.debug('Error during user logout:', error);
    return false;
  }
}

async function registerDeviceToken() {
  if (!isSupported()) {
    console.error("Firebase messaging is not supported by this browser.");
    return;
  }
  
  try {
    if (!messaging) {
      const app = initializeApp(window.firebaseConfig);
      messaging = getMessaging(app);
    }
    
    const fcmToken = await getToken(messaging, { serviceWorkerRegistration });
    
    if (fcmToken) {
      await sendTokenToServer(fcmToken, deviceId);
    } else {
      console.error("No token received from Firebase.");
    }
  } catch (error) {
    console.error("Error fetching FCM token:", error);
  }
}

async function sendTokenToServer(token, deviceId) {
  await fetch('/register_token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') 
    },
    body: JSON.stringify({ token: token, device_id: deviceId })
  });
}

export {
  initializePushNotifications,
  registerDeviceToken,
  updateNotificationToggleUI,
  handleLogout
}; 